import React from "react";
import "./OTPModal.css";

const OTPModal = ({ login, handleCancel }) => {
  const handleOTPSubmit = async (event) => {
    event.preventDefault();
    const otp = event.target.otp.value;
    try {
      login.emit("verify-email-otp", otp);
    } catch (err) {
      console.error("Error confirming OTP:", err);
    }
  };

  return (
    <div className="otp-modal-overlay">
      <div className="otp-modal">
        <h2 className="modal-title">Enter OTP</h2>
        <form onSubmit={handleOTPSubmit} className="modal-form">
          <input
            type="text"
            name="otp"
            placeholder="Enter OTP"
            required
            className="modal-input"
          />
          <button type="submit" className="modal-button modal-submit">
            Submit
          </button>
          <button type="button" onClick={handleCancel} className="modal-button modal-cancel">
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
};

export default OTPModal;