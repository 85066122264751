import React from "react";
import cn from 'classnames';
import styles from "./OrganizationTable.module.css";
import { Table, Dropdown } from "react-bootstrap";

function OrganizationTable({ org, handleUpdateClick, handleDeleteClick }) {


  const ActionMenu = ({ orgId }) => {
    return (
      <Dropdown className={styles.actionMenuContainer}>
        <Dropdown.Toggle
          variant="link"
          id="dropdown-basic"
          className={styles.dropdownToggleButton}
        >
          <i className="bi bi-three-dots-vertical"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item href="#/edit">
            <button className={styles.button} onClick={() => handleUpdateClick(orgId)}>
            <i class="bi bi-pencil"></i> Update
            </button>
          </Dropdown.Item>
          <Dropdown.Item href="#/delete">
            <button className={styles.button} onClick={() => handleDeleteClick(orgId)}><i class="bi bi-x-circle-fill"></i> Delete</button>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <div className={styles.tableOuterContainer}>
      <div className={styles.filterContainer}>Search</div>
      <div className={styles.tableContainer}>
        <Table hover striped={false} bordered={false} className={styles.table}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email ID</th>
              <th>Type</th>
              <th>Wallet Address</th>
              <th>Status</th>
              <th className={styles.actionCell}>Action</th>
            </tr>
          </thead>
          <tbody>
            {org.length > 0? (org.map((orgs) => (
              <tr key={orgs.id}>
                <td>
                  <span className={styles.orgNameCell}>{orgs.name}</span>
                </td>
                <td>
                  <span className={styles.emailIdCell}>{orgs.emailId}</span>
                </td>
                <td>{orgs.type}</td>
                <td>
                  <span className={styles.walletAddressCell}>
                    {orgs.walletAddress}
                  </span>
                </td>
                <td>
                  <span className={cn(styles.status, {[styles.active]: orgs.status?.toLowerCase() === 'active', [styles.pending]: orgs.status?.toLowerCase() === 'pending'})}>
                    {orgs.status}
                  </span>
                  
                </td>
                <td className={styles.actionCell}>
                  <ActionMenu orgId={orgs.id} />
                </td>
              </tr>
            ))):<tr><td style={{textAlign: "center"}} colSpan={6}><span>No Records Found</span></td></tr>}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default OrganizationTable;
