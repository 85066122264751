import styles from './PageHeader.module.css';
export const PageHeader = ({pageName, actions}) => {
    return (
        <div className={styles.pageHeaderContainer}>
            <div className={styles.breadcrumpsContainer}>
                <span className={styles.breadcrumpsItem}>Home</span>
                <span className={styles.seperator}>{'>'}</span>
                <span className={styles.activePageName}>{pageName}</span>
            </div>
            <div className={styles.pageTitleContainer}>
                <span className={styles.pageTitle}>{pageName}</span>
                {actions && <div className={styles.actionContainer}>
                    {actions}
                </div>}
            </div>
        </div>
    )
}