import "./App.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { PageHeader } from "./PageHeader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Magic } from "magic-sdk";
import LoginForm from "./LoginForm";
import AdminPanel from "./AdminPanel";
import Sidebar from "./Sidebar";
import Header from "./Header";
import OTPModal from "./OTPModal";
import UserInfoModal from "./UserInfoModal";
import { jwtDecode } from "jwt-decode";
const PORT = process.env.REACT_APP_PORT;

const magic = new Magic(process.env.REACT_APP_API_KEY,{
  network: {
    rpcUrl: 'https://a0u86ys770:acMLh-eAAJfLVqbbodOHE_t2Tg06Nb0Uvv2rdqrYg0k@a0u0uwems8-a0kezou380-rpc.au0-aws.kaleido.io/', 
  }
});;

function App() {
  const [org, setOrg] = useState([]);
  const [notes, setNotes] = useState([localStorage.getItem('notes')]);
  const [form, setForm] = useState({
    id: null,
    name: "",
    address: "",
    type: "private",
    externalId: "",
    walletAddress: "",
    web3Id: "",
    emailId: "",
    status: "pending",
  });
  const [isUpdating, setIsUpdating] = useState(false);
  const [currentOrgId, setCurrentOrgId] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn'));
  const [userInfo, setUserInfo] = useState(null);
  const [email, setEmail] = useState(localStorage.getItem('email'));
  const [externalId, setExternalId] = useState(localStorage.getItem('externalId'));
  const [activePage, setActivePage] = useState(localStorage.getItem('activePage'));
  const [isUserInfoModalOpen, setIsUserInfoModalOpen] = useState(false);
  const [showOTPModal, setShowOTPModal] = useState(false); 
  const [otpLogin, setOtpLogin] = useState(null);

  useEffect(() => {
    checkUserStatus();
  }, []);

  const checkUserStatus = async () => {
    const status = await magic.user.isLoggedIn();
    const userInfo = await magic.user.getInfo();
    
    const role = localStorage.getItem('role')
    if (status) {
      setIsLoggedIn(true);
      const userInfo = await magic.user.getInfo();
      setUserInfo(userInfo);
      if (role === 'admin') {
        fetchOrganizations();
        fetchNotes()
      } else {
        checkAndFetchUserInformation(userInfo.email);
        fetchMyNotes()
      }
    }
  };

  const login = async (emailAddress) => {
    try {
      setOtpLogin()
      const otpLogin = magic.auth.loginWithEmailOTP({
        email: emailAddress,
        showUI: false,
        deviceCheckUI: false,
      });
      setOtpLogin(otpLogin);
  
      otpLogin
        .on("device-needs-approval", () => {
          console.log("device-needs-approval");
        })
        .on("device-approved", () => {
          console.log("device-approved");
        })
        .on("email-otp-sent", () => {
          setShowOTPModal(true);
        })
        .on("done", async (result) => {
          try {
            const did = result;
            const response = await axios.post(
              `${PORT}login`,
              {},
              {
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": did,
                },
              }
            );
  
            if (response.data.token) {
              const decodedToken = jwtDecode(response.data.token);
              const role = decodedToken.role;
              localStorage.setItem('token', response.data.token);
              localStorage.setItem('role', role);
              const userInfo = await magic.user.getInfo();
              setIsLoggedIn(true);
              localStorage.setItem('isLoggedIn', true);
  
              setUserInfo(userInfo);
              if (role === 'admin') {
                fetchOrganizations();
                fetchNotes();
                setActivePage("dashboard");
                localStorage.setItem('activePage', "dashboard");
                toast.success("Logged in successfully as admin");
              } else {
                checkAndFetchUserInformation(userInfo.email);
                setActivePage("notes");
                localStorage.setItem('activePage', "notes");
                fetchMyNotes();
                toast.success("Logged in successfully as user");
              }
  
              localStorage.setItem('email', emailAddress);
            }
          } catch (err) {
            console.error(err);
          } finally {
            setShowOTPModal(false);
            setOtpLogin(null);
          }
        })
        .on("settled", () => {
          setShowOTPModal(false);
          setOtpLogin(null);
        })
        .catch((err) => {
          console.error(err);
          setShowOTPModal(false);
          setOtpLogin(null);
        });
    } catch (error) {
      if (error.code === "ERR_BAD_REQUEST") {
        toast.error("Access denied! User not invited");
        console.error("Access denied! User not invited", error);
      } else {
        console.error("Login failed:", error);
      }
    }
  };
  const handleLoginSubmit = (e) => {
    e.preventDefault();
    login(email);
  };

  const handleCancelOTP = () => {
    try {
      otpLogin.emit("cancel");
      setShowOTPModal(false);
      setOtpLogin(null);
    } catch (err) {
      console.error("Error canceling OTP login:", err);
    }
  };

  const logout = async () => {
    try {
      await magic.user.logout();
      setIsLoggedIn(false);
      localStorage.setItem('isLoggedIn', false)
      localStorage.removeItem('token');
      localStorage.removeItem('notes')

      setUserInfo(null);
      setOrg([]);
      resetForm();
      localStorage.removeItem('externalId')
      localStorage.setItem('email', '');
      localStorage.setItem('activePage', '');
      localStorage.removeItem('role');
      toast.success("Logged out successfully");
    } catch (error) {
      console.error("Logout failed:", error);
      toast.error("Failed to log out");
    }
  };

  const fetchOrganizations = async () => {
    try {
      const response = await axios.get(
        `${PORT}getOrganizations`
      );
      const sortedData = response.data.sort((a, b) => a.id - b.id);
      setOrg(sortedData);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchNotes = async () => {
    try {
      const response = await axios.get(
        `${PORT}getAllNotes`
      );
      const sortedData = response.data.sort((a, b) => a.id - b.id);
      setNotes(sortedData);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMyNotes = async () => {
    try {
      const response = await axios.post(
        `${PORT}getMyNotes`,
        {"externalId": externalId},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const sortedData = response.data.sort((a, b) => a.id - b.id);
      setNotes(sortedData);
      localStorage.setItem('notes', notes)
    } catch (error) {
      console.error(error);
    }
  };

  const checkAndFetchUserInformation = async (email) => {
    try {
      
      const userInfo = await magic.user.getInfo();
      const response = await axios.get(
        `${PORT}getUserInformation/${email}`
      );
  
      if (!response.data) {
        throw new Error("No user information found");
      }
      let formToSubmit = {
        ...response.data,
        walletAddress: userInfo.publicAddress,
        emailId: userInfo.email,
        status: "active", 
      };
      setExternalId(response.data.externalId)
      localStorage.setItem('externalId', externalId)
      setCurrentOrgId(response.data.id);
      setForm(formToSubmit);
      setIsUpdating(true);
  
      // await axios.put(
      //   `${PORT}updateOrganization/${formToSubmit.id}`,
      //   formToSubmit,
      //   {
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //   }
      // );
    } catch (error) {
      console.error(error);
      if (error.message === "No user information found") {
        toast.error("Request admin to send an email invite");
      } else {
        toast.error("Failed to fetch user information");
      }
  
      setForm({
        id: null,
        name: "",
        address: "",
        type: "private",
        externalId: "",
        walletAddress: userInfo.publicAddress,
        web3Id: "",
        emailId: userInfo.email,
        status: "pending",
      });
    }
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formToSubmit = { ...form };
      const role = localStorage.getItem('role')
      if (role === "admin") {
        formToSubmit.status = "pending";
      } else {
        formToSubmit.status = "active";
      }

      if (role === "admin") {
        if (isUpdating) {
          await axios.put(
            `${PORT}updateOrganization/${currentOrgId}`,
            formToSubmit,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          fetchOrganizations();
          toast.success("Organization updated successfully");
        } else {
          const response = await axios.post(
            `${PORT}createOrganization`,
            formToSubmit,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          setOrg([...org, response.data]);
          fetchOrganizations();
          toast.success("Organization added successfully");
          resetForm();
        }
      } else {
        if (currentOrgId != null) {
          await axios.put(
            `${PORT}updateOrganization/${currentOrgId}`,
            formToSubmit,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          toast.success("Information updated successfully");
        } else {
          const response = await axios.post(
            `${PORT}createOrganization`,
            formToSubmit,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          setOrg([...org, response.data]);
          toast.success("Organization added successfully");
          resetForm();
        }
      }
    } catch (error) {
      console.error(error);
      toast.error(
        isUpdating
          ? "Failed to update organization"
          : "Failed to add organization"
      );
    }
  };


  const handleDeleteClick = async (id) => {
    try {
      await axios.delete(`${PORT}deleteOrganization/${id}`);
      fetchOrganizations();
      toast.success("Organization deleted successfully");
    } catch (error) {
      console.error(error);
      toast.error("Failed to delete organization");
    }
  };

  const resetForm = () => {
    setForm({
      id: null,
      name: "",
      address: "",
      type: "private",
      externalId: "",
      walletAddress: "",
      web3Id: "",
      emailId: "",
      status: "pending",
    });
    setIsUpdating(false);
    setCurrentOrgId(null); 
  };

  const toggleUserInfoModal = () => {
    setIsUserInfoModalOpen(!isUserInfoModalOpen);
  };
  const role = localStorage.getItem('role')
  return (
    <div className="App">
      {isLoggedIn && activePage ? (
        <>
          <Header
            logout={logout}
            userInfo={userInfo}
            toggleUserInfoModal={toggleUserInfoModal}
          />
          <Sidebar
            activePage={activePage}
            email={email}
            setActivePage={setActivePage}
            userInfo={userInfo}
          />
          <div className="main-content">
            {activePage === "organization" ? (
              <AdminPanel
                org={org}
                form={form}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                isUpdating={isUpdating}
                setIsUpdating={setIsUpdating}
                setForm={setForm}
                handleDeleteClick={handleDeleteClick}
                logout={logout}
                setCurrentOrgId={setCurrentOrgId}
              />
            ) : activePage === 'notes' ? (
              <div>
                <PageHeader pageName={activePage} />
                <div className="main-page-content">
                  {notes.length === 0 ? (
                    <div>Currently there are no active Notes</div>
                  ) : (
                    <div>
                      {role === "admin" ? (
                        <table className="notes-table">
                          <thead>
                            <tr>
                              <th>Loan ID</th>
                              <th>Note Name</th>
                              <th>Balance</th>
                              <th>Description</th>
                              <th>Filing Date</th>
                              <th>Closing Date</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {notes.map((note) => (
                              <tr key={note.id}>
                                <td>{note.loanId}</td>
                                <td>{note.noteName}</td>
                                <td>$ {note.balance}</td>
                                <td>{note.balanceDescription}</td>
                                <td>{new Date(note.fillingDate).toLocaleString()}</td>
                                <td>{new Date(note.closingDate).toLocaleString()}</td>
                                <td>{note.control}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <div className="notes-grid">
                          {notes.map((note) => (
                            <div key={note.id} className="note-card">
                              <h3>{note.noteName}</h3>
                              <p>Loan ID: {note.loanId}</p>
                              <p>Parent Loan ID: {note.parentLoanId}</p>
                              <p>Control: {note.control}</p>
                              <p>Balance: {note.balance}</p>
                              <p>Balance Description: {note.balanceDescription}</p>
                              <p>Holder ID: {note.holderId}</p>
                              <p>Parent Holder ID: {note.parentHolderId}</p>
                              <p>Report ID: {note.reportId}</p>
                              <p>Filling Date: {new Date(note.fillingDate).toLocaleString()}</p>
                              <p>Closing Date: {new Date(note.closingDate).toLocaleString()}</p>
                              <p>Created At: {new Date(note.createdAt).toLocaleString()}</p>
                              <p>Updated At: {new Date(note.updatedAt).toLocaleString()}</p>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            
            ):(
              <div>
                <PageHeader pageName={activePage} />
                <div className="main-page-content">
                  Welcome to {activePage}
                </div>
              </div>
            )
            }
          </div>
        </>
      ) : (
        <LoginForm
          email={email}
          handleLoginSubmit={handleLoginSubmit}
          setEmail={setEmail}
        />
      )}
      <UserInfoModal
        isOpen={isUserInfoModalOpen}
        toggle={toggleUserInfoModal}
        userInfo={userInfo}
      />
      {showOTPModal && <OTPModal login={otpLogin} handleCancel={handleCancelOTP} />}
      <ToastContainer />
    </div>
  );
}

export default App;
